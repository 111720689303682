<template>
  <div class="container">
    <div class="detal1">
      <h3>开票企业信息</h3>
      <span class="span">申请发票金额：</span>
      {{ info.receipt_amount | unitPrice("￥") }}
      <br />
      <span class="span">申请发票类型：</span>
      <!-- {{ info.type == 1 ? "商品订单发票" : "服务订单发票" }} -->
      {{ info.receipt_type == 'ELECTRO' ? "电子普通发票" : info.receipt_type == 'VATORDINARY' ? "增值税普通发票" : "增值税专用发票" }}
      <br />
      <span class="span">申请企业名称：</span>
      {{ info.receipt_title }}
      <br />
      <span class="span">开户银行：</span>
      {{ info.bank_name }}
      <br />
      <span class="span">开户账号：</span>
      {{ info.bank_account }}
      <br />
      <span class="span">收货人：</span>
      {{ info.receive_name }}
      <br />
      <span class="span">申请时间：</span>
      {{ info.create_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}
      <br />
      <span class="span">联系电话：</span>
      {{ info.receive_phone }}
      <br />
      <span class="span">发票抬头：</span>
      {{ info.receipt_title }}
      <br />
      <span class="span">企业税号：</span>
      {{ info.tax_no }}
      <br />
    </div>
    <div class="detal">
      <h3>开票订单信息</h3>
      <!-- <template>
        <el-table align="center" :data="tableData.data" style="width: 100%">
          <el-table-column  align='center' prop="sn" label="订单号" width="180"></el-table-column>
          <el-table-column  align='center' prop="complete_time" label="订单完成时间">
						<template slot-scope="scope">{{scope.row.complete_time | unixToDate("yyyy-MM-dd hh:mm:ss")}}</template>
					</el-table-column>
          <el-table-column  align='center' prop="goods_name" label="商品名称"></el-table-column>
          <el-table-column  align='center' prop="goods_num" label="商品数量"></el-table-column>
          <el-table-column  align='center' prop="price" label="商品单价">
						<template slot-scope="scope">{{scope.row.price  | unitPrice("￥")}}</template>
					</el-table-column>
          <el-table-column  align='center' prop="address" label="开票金额">
						<template slot-scope="scope">{{scope.row.create_time  | unitPrice("￥")}}</template>
					</el-table-column>
        </el-table>
      </template> -->
      <div>
        <table
          cellspacing="0"
          cellpadding="0"
          border="1"
          class="el-table-yuepp"
        >
          <!-- 表头开始 -->
          <thead style="width: 100%">
            <tr class="el-table-tr">
              <th
                colspan="1"
                class="el-table_3_column_1 el-table-column--selection is-leaf"
              >
                <div class="cell">订单号</div>
              </th>
              <th
                colspan="1"
                class="el-table_3_column_2 el-table-column--selection is-leaf"
              >
                <div class="cell">订单完成时间</div>
              </th>

              <th
                colspan="1"
                class="el-table_3_column_5 el-table-column--selection is-leaf"
              >
                <div class="cell">商品名称</div>
              </th>
              <th
                colspan="1"
                class="el-table_3_column_6 el-table-column--selection is-leaf"
              >
                <div class="cell">单价</div>
              </th>
              <th
                colspan="1"
                class="el-table_3_column_7 el-table-column--selection is-leaf"
              >
                <div class="cell">数量</div>
              </th>
              <th
                colspan="1"
                class="el-table_3_column_4 el-table-column--selection is-leaf"
              >
                <div class="cell">开票金额</div>
              </th>
            </tr>
          </thead>
          <!-- 表头完 -->
          <!-- 表身 -->
          <tbody v-for="(item, index) of tableData.data" :key="index">
            <tr v-for="(itemI, index_I) of item.items_json" :key="index_I">
              <td v-if="!index_I" :rowspan="item.items_json.length">
                {{ item.sn }}
              </td>
              <td v-if="!index_I" :rowspan="item.items_json.length">
                {{ item.complete_time | unixToDate }}
              </td>
              <td>{{ itemI.name }}</td>
              <td>{{ itemI.cost_price ? itemI.cost_price : 0 | unitPrice("￥") }}</td>
              <td>{{ itemI.num }}</td>
              <td v-if="!index_I" :rowspan="item.items_json.length">
                {{ item.supplier_order_price | unitPrice("￥") }}
              </td>
            </tr>
          </tbody>
          <!-- 表身完 -->
        </table>
      </div>
      <el-pagination
        v-if="tableData.data_total > 10"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="params.page_no"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
        :page-size="params.page_size"
        :total="tableData.data_total"
      ></el-pagination>
    </div>
    <div class="detal" v-if="info.receipt_type !== 'ELECTRO'">
      <h3>物流详情</h3>
      <template v-if="!info.logi_name && !info.logi_id">
        （暂无）
      </template>
      <template v-else>
        <el-steps direction="vertical" :active="0">
          <el-step
            v-for="(item, index) in logisticsData.data"
            :title="item.context"
            :description="item.time"
            :key="index"
          ></el-step>
        </el-steps>
      </template>
    </div>
    <div class="detal" v-else>
      <h3>发票详情</h3>
      <div style="display: flex; flex-flow: wrap">
        <div style="margin-right: 10px;" v-for="(item, index) in fileList" :key="index">
          <img src="../../assets/images/zip.jpeg" alt="" v-if="item.file_type === 'zip' || item.file_type === 'rar'">
          <img src="../../assets/images/pdf.png" alt="" v-if="item.file_type === 'pdf'"><br>
          <el-tooltip class="fileName" effect="dark" :content="item.file_name" placement="top-start">
            <span>{{ item.file_name }}</span>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as API_goods from "@/api/goods";
import * as API_Account from "@/api/account";
export default {
  name: "goodsAudit",
  data() {
    return {
      id: this.$route.params.id,
      tableData: {},
      info: {},
      params: {
        page_no: 1,
        page_size: 20,
        orderstr: "",
        type: 1
      },
      /** 物流信息 */
      logisticsData: [],
      // 文件列表
      fileList: []
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getDetail();
  },
  mounted() {
    this.id = this.$route.params.id;
  },
  activated() {},
  methods: {
    GET_Express() {
      if (this.info.logi_name && this.info.logi_id && this.info.delivery_no) {
        API_Account.getExpressDetail({
          id: this.info.logi_id, //快递公司
          num: this.info.delivery_no, //物流单号
          mobile:this.info.receive_phone//电话
        }).then(res => {
          this.logisticsData = res;
        });
      }
    },
    getDetail() {
      API_Account.getInfo(this.id).then(res => {
        this.info = res;
        if (res.file) {
          res.file.split(',').filter(item => { return item !== ''}).forEach(item1 => {
            let obj = {}
            obj['file_type'] = item1.split('/')[5].split('.')[1]
            obj['file_name'] = item1.split('/')[5]
            obj['url'] = item1
            this.fileList.push(obj)
          })
        }
        this.params.orderstr = res.orderstr;
        this.getDetailList();
        this.GET_Express();
        // this.getTitleAndTaxNo();
      });
    },
    getTitleAndTaxNo(){
      API_Account.getTitleAndTaxNo().then(res => {
        // 暂未用到
      });
    },
    getDetailList() {
      API_Account.getOrderList(this.params).then(res => {
        res.data.map(item => {
          item.items_json = JSON.parse(item.items_json);
        });
        this.tableData = res;
        this.params.page_no = res.page_no;
        this.params.page_size = res.page_size;
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.getDetailList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.getDetailList();
    }
  }
};
</script>

<style scoped  lang="scss">
.fileName{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
.cont {
  margin-left: 30px;
  padding-bottom: 20px;
}
.container {
  background: #fff;
  position: relative;
  padding: 20px;
  border-radius: 5px;
}
.detal1 {
  line-height: 35px;
}
.detal {
  line-height: 35px;
}
.span {
  display: inline-block;
  width: 150px;
  text-align: right;
}
.el-table-tr {
  width: 100%;
  background-color: rgb(230, 236, 247);
  text-align: center;
  color: #909399;
  font-weight: 500;
}
.el-table-tr th {
  border: 1px solid #dcdfe6;
  height: 50px;
}
.el-table_3_column_1 {
  flex: 4;
}
.el-table_3_column_2 {
  flex: 4;
}
.el-table_3_column_3 {
  flex: 2;
}
.el-table_3_column_4 {
  flex: 2;
}
.el-table_3_column_5 {
  flex: 4;
}
.el-table_3_column_6 {
  flex: 2;
}
.el-table_3_column_7 {
  flex: 2;
}
.el-table-yuepp {
  width: 100%;
  border: 1px solid #dcdfe6;
}
td {
  text-align: center;
  height: 60px;
  border: 1px solid #dcdfe6;
  font-size: 14px;
  color: #606266;
}
/deep/ .el-step__title.is-wait {
  color: #666;
}
</style>
